import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import store from "@/store/index.js";
import intTelInputDemo from "./intel-input-demo.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    clientList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    company: "",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
    mergePopup: false,
    mergeMainClient: false,
    mergeMainOtherClient: false,
    mergeTransferClient: false,
    main_common_emails: 0,
    old_common_emails: 0,
    total_other_clients: 0,
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    PaginationComponent,
    intTelInputDemo,
  },

  methods: {
    closeMergePopup() {
      let _vm = this;
      _vm.mergePopup = false;
      _vm.mergeMainClient = false;
      _vm.mergeMainOtherClient = false;
      _vm.mergeTransferClient = false;
      _vm.main_common_emails = 0;
      _vm.old_common_emails = 0;
      _vm.total_other_clients = 0;
    },
    togglePopup() {
      let _vm = this;
      store.state.isLoaderShow = true;
      this.axios
        .get("other-clients/start-merge")
        .then((response) => {
          _vm.main_common_emails = response.data.data.main_common_emails;
          _vm.mergePopup = true;
          _vm.mergeMainClient = true;
          store.state.isLoaderShow = false;
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },
    mergeClientWithMainClient() {
      store.state.isLoaderShow = true;
      let _vm = this;
      _vm.axios
        .get("other-clients/merge-to-clients")
        .then((response) => {
          _vm.old_common_emails = response.data.data.old_common_emails;
          store.state.isLoaderShow = false;
          _vm.mergeMainClient = false;
          _vm.mergeMainOtherClient = true;
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },
    mergeClientWithOtherClient() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.axios
        .get("other-clients/merge-to-old-clients")
        .then((response) => {
          _vm.total_other_clients = response.data.data.total_other_clients;
          store.state.isLoaderShow = false;
          _vm.mergeMainClient = false;
          _vm.mergeMainOtherClient = false;
          _vm.mergeTransferClient = true;
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },
    transferToOldClient() {
      store.state.isLoaderShow = true;
      let _vm = this;
      _vm.axios
        .get("other-clients/transfer-to-old-clients")
        .then((response) => {
          _vm.getAll();
          _vm.mergePopup = false;
          store.state.isLoaderShow = false;
          _vm.mergeMainClient = false;
          _vm.mergeMainOtherClient = false;
          _vm.mergeTransferClient = false;
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.selectedFile = []; // Clear the selectedFile array
      Array.prototype.forEach.call(files, (item) => {
        this.selectedFile.push(item); // Push each file item to the selectedFile array
      });
    },
    uploadOldClient() {
      store.state.isLoaderShow = true;
      if (this.selectedFile && this.selectedFile.length > 0) {
        let _vm = this;
        let fd = new FormData();
        fd.append("xlsx", this.selectedFile[0]);
        _vm.axios.post("/import/other-clients", fd).then(() => {
          _vm.selectedFile = [];
          document.getElementById("file-input").value = null;
          _vm.getAll();
          store.state.isLoaderShow = false;
        });
      } else {
        this.$toast.error("Please select a file first!");
      }
    },
    exportOldClient() {
      store.state.isLoaderShow = true;
      this.axios.get("/export/other-clients").then((response) => {
        let a = document.createElement("a");
        a.href = response.data.data.file;
        a.download = response.data.data.name;
        a.click();
        store.state.isLoaderShow = false;
      });
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send GET request to retrieve client data
      this.axios
        .get("/other-clients" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.clientList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          // Set isSelected property to false for each client
          // for (let index = 0; index < data.length; index++) {
          //   data[index]["isSelected"] = false;
          // }
          // _vm.clientList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {
          // Handle errors here if necessary
        });
    },
  },
  mounted() {
    this.getAll();
  },
};
